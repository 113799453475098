import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
import { useSetRecoilState } from 'recoil';
import { themeConfigState, seoDataState } from '../../common/States';
import SEO from '../components/SEO';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import AniLink from 'gatsby-plugin-transition-link/AniLink';

import { Alert } from '@material-ui/lab';

const useStyles = makeStyles(theme => {
  return {
    logo: {
      height: 40,
      [theme.breakpoints.down('sm')]: {
        height: 30,
      },
    },
    navLink: {
      color: theme.palette.link_primary_color,
      fontSize: '1rem',
      textDecoration: 'none',
      cursor: 'pointer',
      '&:visited': {
        color: props => theme.palette.link_primary_color,
      },
      '&:hover': {
        color: props => theme.palette.link_hover_color,
        textDecoration: 'underline',
      },
    },
  };
});

export const query = graphql`
  query getSignedout {
    prismic {
      allThemes {
        edges {
          node {
            dark_background
            dark_text
            error_color
            info_color
            light_background
            warning_color
            light_text
            primary_background
            primary_color
            primary_text
            secondary_background
            secondary_color
            secondary_text
            success_color
            link_primary_color
            link_hover_color
            nav_link_color
            nav_link_hover_color
            nav_link_active_color
          }
        }
      }
      allTop_menu_navigations {
        edges {
          node {
            logo_image
            color
            nav_links_color
            nav_links_hover_color
            nav_links_active_color
            position
            menu_buttons {
              variant
              link {
                ... on PRISMIC_Home_page {
                  _meta {
                    uid
                  }
                }
                ... on PRISMIC_Signup_page {
                  _meta {
                    uid
                  }
                }
                ... on PRISMIC_Signin_page {
                  _meta {
                    uid
                  }
                }
                ... on PRISMIC_Page {
                  _meta {
                    uid
                  }
                }
              }
              text
              color
              size
            }
            navigation_links {
              link {
                ... on PRISMIC_Home_page {
                  _meta {
                    uid
                  }
                }
                ... on PRISMIC_Signup_page {
                  _meta {
                    uid
                  }
                }
                ... on PRISMIC_Signin_page {
                  _meta {
                    uid
                  }
                }
                ... on PRISMIC_Page {
                  _meta {
                    uid
                  }
                }
              }
              text
              underline
              variant
            }
          }
        }
      }
      allFooters {
        edges {
          node {
            background_color
            copy_right_text
            facebook_link {
              ... on PRISMIC__ExternalLink {
                _linkType
                url
              }
            }
            instagram_link {
              ... on PRISMIC__ExternalLink {
                _linkType
                url
              }
            }
            site_map {
              label
              link_group
              link {
                ... on PRISMIC_Page {
                  _meta {
                    uid
                  }
                }
                ... on PRISMIC_Home_page {
                  _meta {
                    uid
                  }
                }
                ... on PRISMIC_Signup_page {
                  _meta {
                    uid
                  }
                }
                ... on PRISMIC_Signin_page {
                  _meta {
                    uid
                  }
                }
              }
            }
            text_color
          }
        }
      }
    }
  }
`;

const SignedOutPage = props => {
  const setThemeConfig = useSetRecoilState(themeConfigState);
  const setSeoData = useSetRecoilState(seoDataState);

  const themeFirstEdge = props.data.prismic.allThemes.edges.slice(0, 1).pop();
  const themeConfig = themeFirstEdge.node;

  const topMenuFirstEdge = props.data.prismic.allTop_menu_navigations.edges
    .slice(0, 1)
    .pop();
  const topMenuData = topMenuFirstEdge.node;

  const logoImage = topMenuData.logo_image;

  const classes = useStyles();

  useEffect(() => {
    setThemeConfig(themeConfig);
    setSeoData({
      title: 'Tripbuddi Signin Successful',
      description: 'Tripbuddi Signin Successful',
    });
  }, []);

  return (
    <>
      <SEO
        className={classes.logo}
        title="Tripbuddi Signin Successful"
        desc="Tripbuddi Signin Successful"
      />
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        minHeight="90vh"
        zIndex={1}
      >
        <Container maxWidth="xs">
          <Paper className={classes.paper}>
            <Box py={2} textAlign="center" className={classes.title}>
              <AniLink fade duration={1} to="/">
                <img
                  className={classes.logo}
                  src={logoImage.url}
                  alt={logoImage.alt}
                />
              </AniLink>
            </Box>
            <Box px={2} pb={3}>
              <Alert severity="success">Sign out successful</Alert>
            </Box>
            <Box textAlign="center" pb={3}>
              <AniLink className={classes.navLink} fade duration={1} to="/">
                {'Return to home'}
              </AniLink>
            </Box>
          </Paper>
        </Container>
      </Box>
    </>
  );
};

export default SignedOutPage;
